import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import VisitPage from "../utils/visit-page"

const TopPostDetails = ({ slug, title, image, alt }) => {
  const titleClass = `
    lg:text-[28px]
    text-[22px] 
    font-semibold 
    pt-4
    lg:pt-0
    lg:leading-10
    leading-7
    `
  return (
    <Link
      to={`/${slug}`}
      className={`grid md:grid-cols-4 grid-cols-1 top-post hover:opacity-50`}
    >
      <div className={`col-span-1 max-w-[80%] m-auto md:order-1 order-2`}>
        <h3 className={`${titleClass} `}>{title}</h3>
        <VisitPage text="Read article" />
      </div>

      <GatsbyImage
        image={image}
        alt={alt}
        className={`col-span-3 lg:h-[503px]  md:h-[400px] h-[300px] md:order-2 order-1`}
      />
    </Link>
  )
}

export default TopPostDetails
