import React from "react"

import Layout from "../components/layout"
import PageHeader from "../components/utils/page-header"
import Post from "../components/thoughts"

const Thoughts = () => {
  const seo = {
    title: "Our thoughts",
    description: "Our thoughts, naked and bare",
  }
  return (
    <Layout seo={seo}>
      <PageHeader
        text={
          <>
            Our thoughts, <br />
            naked and bare
          </>
        }
      />
      <Post/>
    </Layout>
  )
}

export default Thoughts
