import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import BlogItem from "./blog-item"
import TopPost from "./top-post-details"

const Post = () => {
    const thoughts = useStaticQuery(graphql`
    {
        allWpPost (sort: {fields: date, order: DESC}){
            nodes {
            title
            slug
            link
            excerpt
            content
            featuredImage {
                node {
                localFile {
                    childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
                    }
                }
                }
            }
            }
        }
    }
    `)
   
        const topPost = thoughts.allWpPost.nodes.filter((_, index)=> index === 0).map((item) => (
            <TopPost image={item.featuredImage.node.localFile.childImageSharp.gatsbyImageData} title={item.title} slug={item.slug} alt={item.title}/>
        ))

        const allThoughts = thoughts.allWpPost.nodes.filter((_, index) => index > 0).map((item, i) => (
        <BlogItem 
            key={i}
            image={item.featuredImage.node.localFile.childImageSharp.gatsbyImageData} 
            alt={item.title} 
            title={item.title} 
            slug={item.slug}
            imageClass={`h-[350px] mb-[16px] object-contain `}
            excerpt = {item.excerpt}
        />
    ))

    return (
        <div>
            {topPost}
            <div className={`grid lg:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-[1px] gap-y-4 mt-[1px] mx-auto pb-16`}>
            {allThoughts}
            </div>
        </div>
    )
}

export default Post
