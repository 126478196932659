import React from "react"
import ViewProjectArrow from "../icons/view-project-arrow"

const VisitPage = ({ text = "View Project", additionalStyle }) => {
  return (
    <div className={`flex items-center pb-8 ${additionalStyle}`}>
      <p className="text-base font-semibold min-w-max mb-0">{text}</p>
      <span className={`transition-transform`}>
        <ViewProjectArrow />
      </span>
    </div>
  )
}

export default VisitPage
