import React from 'react'
import {Link} from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import VisitPage from "../utils/visit-page"


const BlogItem = ({alt, image, title, slug}) => {
    return (
        <div className="hover:opacity-50 top-post">
          <Link to={`/${slug}`}>
            <GatsbyImage image={image}
              alt={alt} className={`lg:h-[426px] sm:h-[250px] h-[180px] mb-[16px] object-contain`}
              />
            
                <div className={`w-[80%] mx-auto lg:mt-[41px] text-[#292A2C]`}>
                  <h3 className={`lg:text-[22px] text-[18px] font-semibold md:leading-7 leading-6 md:h-[85px] h-[105px]`}>{title}</h3>
                   <VisitPage text="Read article" additionalStyle="mt-16 sm:mt-8 lg:mt-16"/>
                </div>
           </Link>
        </div>
    )
}

export default BlogItem
